import React from "react";

import './Products.css';

import Header from '../Partials/Header/Header';
import Footer from '../Partials/Footer/Footer';
// import ProductShowcase from "../Home/ProductShowcase/ProductShowcase";

import lines from '../../../src/assets/database/lines.json';
import banner from '../../assets/img/banner/productsBanner.jpg';

import termo from '../../assets/img/products/NatalCareLX.jpg';
import venti from '../../assets/img/products/SLE.jpg';
import bombas from '../../assets/img/products/MDK.jpg';
import otras from '../../assets/img/products/vathin.png';

function Products() {

        return (
                <div className="products">
                        <Header />
                        <div className="productsContent">
                                <div className="productsHeader">
                                        <img className="productsBanner" src={banner} alt="productsBanner"></img>
                                        <h1 className="productsTitle">LINEAS DE NEGOCIOS</h1>
                                </div>
                                <div className="productsLines">
                                        <div className="productsLineBox">

                                                <img className="productsLineImg" src={termo} alt="termoLine"></img>
                                                <h3 className="productsLineTitle">{lines[0].name}</h3>
                                                <div className="productsList">
                                                        <li className="productsListItem">NatalCare LX</li>
                                                        <li className="productsListItem">(Incubadora Medix)</li>
                                                        <li className="productsListItem"></li>
                                                </div>

                                        </div>
                                        <div className="productsLineBox">
                                                <img className="productsLineImg" src={venti} alt="ventiLine"></img>
                                                <h3 className="productsLineTitle">{lines[1].name}</h3>
                                                <div className="productsList">
                                                        <li className="productsListItem">SLE6000</li>
                                                        <li className="productsListItem">(Ventilador Neonatal de Alta Frecuencia)</li>
                                                        <li className="productsListItem"></li>
                                                        <li className="productsListItem"></li>
                                                        <li className="productsListItem"></li>
                                                </div>

                                        </div>
                                        <div className="productsLineBox">
                                                <img className="productsLineImg" src={bombas} alt="bombasLine"></img>
                                                <h3 className="productsLineTitle">{lines[2].name}</h3>
                                                <div className="productsList">
                                                        <li className="productsListItem">MDK (Bomba Volumétrica)</li>
                                                        <li className="productsListItem">MDK (Bomba a Jeringa)</li>
                                                </div>
                                        </div>
                                        <div className="productsLineBox">
                                                <img className="productsLineImg" src={otras} alt="bombasLine"></img>
                                                <h3 className="productsLineTitle">{lines[3].name}</h3>
                                                <div className="productsList">
                                                        <li className="productsListItem">Vathin</li>
                                                        <li className="productsListItem">(Broncoscopio)</li>
                                                        <li className="productsListItem">MediLED</li>
                                                        <li className="productsListItem">(Fototerapia)</li>
                                                        <li className="productsListItem">Bilicare</li>
                                                        <li className="productsListItem">(Bilirrubinómetro)</li>
                                                        <li className="productsListItem">Biogenesis</li>
                                                        <li className="productsListItem">(Sensores)</li>
                                                        <li className="productsListItem">Vivolight</li>
                                                        <li className="productsListItem">(Localizador de Venas)</li>
                                                        <li className="productsListItem">MDK</li>
                                                        <li className="productsListItem">(Descartables para Bombas de infusión)</li>
                                                </div>

                                        </div>
                                </div>
                        </div>
                        {/* <ProductShowcase /> */}
                        <Footer />
                </div>
        );
}

export default Products;