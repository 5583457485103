import React from "react";
import { Link } from "react-router-dom";

import './Promo.css';

import arg from '../../../assets/img/countries/ararg.svg';
import uy from '../../../assets/img/countries/uy.svg';
import cl from '../../../assets/img/countries/cl.svg';
import bo from '../../../assets/img/countries/bo.svg';
import pe from '../../../assets/img/countries/pe.svg';
import ec from '../../../assets/img/countries/ec.svg';
import co from '../../../assets/img/countries/co.svg';
import py from '../../../assets/img/countries/py.svg';
import mx from '../../../assets/img/countries/mx.svg';
import us from '../../../assets/img/countries/us.svg';

import medix from '../../../assets/img/prov/medix.jpg';
import biogenesis from '../../../assets/img/prov/biogenesis.png';
import mdk from '../../../assets/img/prov/mdk.png';
import mennen from '../../../assets/img/prov/mennen.png';
import sle from '../../../assets/img/prov/sle.png';
import vathin from '../../../assets/img/prov/vathin.png';
import vivolight from '../../../assets/img/prov/vivolight.png';

function Promo() {
    return (
        <div className="promo">
            <div className="promoAContainer">
                <div className="promoCountries">
                    <Link to='/about' className="promoLinkA">
                        <div className="countriesContainer">
                            <h1 className="titleA">Proveedores y Países</h1>
                            <div className="promoProvList">
                                <img className="provLogoC" src={mdk} alt="mdk"></img>
                                <img className="provLogoG" src={vivolight} alt="vivolight"></img>
                                <img className="provLogoA" src={sle} alt="sle"></img>
                                <img className="provLogoB" src={medix} alt="medix"></img>
                                <img className="provLogoE" src={mennen} alt="mennen"></img>
                                <img className="provLogoF" src={vathin} alt="vathin"></img>
                                <img className="provLogoD" src={biogenesis} alt="biogenesis"></img>
                            </div>
                            <div className="promoCountriesList">
                                <img className="countryFlagP" src={cl} alt="cl"></img>
                                <img className="countryFlagP" src={bo} alt="bo"></img>
                                <img className="countryFlagP" src={arg} alt="arg"></img>
                                <img className="countryFlagP" src={co} alt="co"></img>
                                <img className="countryFlagP" src={us} alt="us"></img>
                                <img className="countryFlagP" src={mx} alt="mx"></img>
                                <img className="countryFlagP" src={ec} alt="ec"></img>
                                <img className="countryFlagP" src={uy} alt="uy"></img>
                                <img className="countryFlagP" src={py} alt="py"></img>
                                <img className="countryFlagP" src={pe} alt="pe"></img>
                            </div>
                        </div>

                    </Link>
                </div>
            </div>
            <div className="promoBContainer">
                <Link to='/products' className="promoLink">
                    <p className="boxTitle">Equipamiento Médico</p>
                    <div className="boxTextContainer">
                        <p className="boxText">Nuestra visión es facilitar el acceso a equipamiento médico, materias primas, materiales y repuestos de alta calidad a distribuidores, fabricantes, desarrolladores relacionados con la salud humana y animal. Ser el nexo entre proveedores y clientes de la mejor solución en la distribución, fabricación y manutención de equipamiento médico.</p>
                    </div>
                    <Link to='/products' className="bannerLinkB">
                        <button className="bannerButtonB">Ver lista de Productos</button>
                    </Link>
                </Link>
            </div>
        </div>
    );
}

export default Promo;