import React from "react";

import './Contact.css';

import Header from '../../Partials/Header/Header';
import Footer from '../../Partials/Footer/Footer';

function Contact() {
    return (
        <div className="contact">
            <Header />
            <div className="contactContent">
                <h1 className="contactTitle">CONTACTO</h1>
                <div className="contactBox">
                    <div className="contactEmailBox">
                        <div className="emailBox">
                            <p className="contactEmail"><a href="mailto:sales@xidemmedical.com">sales@xidemmedical.com</a></p>
                            <p className="contactEmail"><a href="mailto:victoria.cerverizzo@xidemmedical.com">Comex: victoria.cerverizzo@xidemmedical.com</a></p>
                        </div>
                        <div className="emailBox">
                            <p className="contactEmail"><a href="mailto:customerservices@xidemmedical.com">customerservices@xidemmedical.com</a></p>
                            <p className="contactEmail"><a href="mailto:gonzalo.lodos@xidemmedical.com">Finance: gonzalo.lodos@xidemmedical.com</a></p>
                        </div>
                    </div>
                    <div className="contactNumbers">
                        <div className="contact1">
                            <h2 className="contactSubtitle">Ventas</h2>
                            <p className="contactNumber">Texto al +1 (954) 686 4810</p>
                        </div>
                        <div className="contact1">
                            <h2 className="contactSubtitle">Finanzas</h2>
                            <p className="contactNumber">+54 9 11 6457 1635</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Contact;